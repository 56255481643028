import React from "react";
import Nav from "../../components/nav/nav";
import Social from "../../components/social/social";
import Header from "../../components/header/header";
import styles from "./contact.module.css";
import {COLOR, FONT} from "../../styles/constants";

export default function Contact() {
    return (
      <div id='contact' className={styles.container}>
        
        <div className={styles.nav}>
          <Nav/>
        </div>
      
        <svg className={styles.image} id="a03d5d0a-d0e8-408f-b0b8-c0372bdb0623" data-name="e7cc71de-40c1-4f9a-93a1-42e94b76b12e" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1599 723">
          <rect x="368.17" y="554.7" width="24.67" height="141.98" fill="#c8412e"/>
          <rect x="417.22" y="611.36" width="24.68" height="85.33" fill="#c8412e"/>
          <rect x="417.22" y="573.79" width="24.68" height="85.33" fill="#c8412e"/>
          <rect x="681.17" y="616.45" width="16.78" height="80.23" fill="#c8412e"/>
          <rect x="613.68" y="611.54" width="16.77" height="85.14" fill="#c8412e"/>
          <rect x="569.74" y="567.75" width="26.74" height="107.21" fill="#c8412e"/>
          <rect x="649.04" y="550.61" width="27.78" height="124.34" fill="#c8412e"/>
          <polygon points="338.97 698.46 338.97 572.83 306.65 556.49 306.65 698.46 338.97 698.46" fill="#c8412e"/>
          <rect x="221.16" y="616.45" width="27.12" height="87.72" fill="#c8412e"/>
          <rect x="285.35" y="616.45" width="16.83" height="87.72" fill="#c8412e"/>
          <rect x="175.96" y="592.91" width="16.83" height="111.27" fill="#c8412e"/>
          <rect x="199.64" y="576.92" width="8.42" height="98.84" fill="#c8412e"/>
          <rect x="234.79" y="559.65" width="8.42" height="98.84" fill="#c8412e"/>
          <rect x="110.58" y="675.46" width="21.93" height="47.54" fill="#c8412e"/>
          <rect x="140.56" y="656.16" width="12.74" height="35.08" fill="#c8412e"/>
          <rect x="162.49" y="675.46" width="13.61" height="47.54" fill="#c8412e"/>
          <rect x="74.03" y="661.87" width="13.61" height="61.13" fill="#c8412e"/>
          <rect x="93.18" y="652.64" width="6.81" height="57.06" fill="#c8412e"/>
          <rect x="130.37" y="633.73" width="6.81" height="77.18" fill="#c8412e"/>
          <rect x="1491.37" y="684.84" width="18.97" height="38.16" fill="#c8412e"/>
          <rect x="1473.39" y="669.94" width="11.02" height="28.17" fill="#c8412e"/>
          <rect x="1453.67" y="685.43" width="11.77" height="37.57" fill="#c8412e"/>
          <rect x="1530.19" y="674.28" width="11.78" height="48.72" fill="#c8412e"/>
          <rect x="1551.19" y="683.37" width="11.78" height="39.63" fill="#c8412e"/>
          <rect x="1519.51" y="667.1" width="5.88" height="55.9" fill="#c8412e"/>
          <rect x="1487.33" y="651.92" width="5.89" height="61.98" fill="#c8412e"/>
          <rect x="343.49" y="576.92" width="49.35" height="119.76" fill="#c8412e"/>
          <rect x="960.1" y="460.62" width="24.2" height="228.84" fill="#e25b4d"/>
          <rect x="1046.38" y="446.99" width="24.21" height="242.46" fill="#e25b4d"/>
          <rect x="902.34" y="390.61" width="31.71" height="298.84" fill="#e25b4d"/>
          <rect x="1098.91" y="256.67" width="36.69" height="424" fill="#e25b4d"/>
          <rect x="1115.45" y="130.39" width="4.6" height="418.93" fill="#e25b4d"/>
          <rect x="847.35" y="443.24" width="44.39" height="236.34" fill="#e25b4d"/>
          <rect x="921.77" y="493.21" width="48.41" height="190.89" fill="#e25b4d"/>
          <rect x="992.31" y="530.68" width="48.42" height="158.77" fill="#e25b4d"/>
          <rect x="921.77" y="518.93" width="195.41" height="154.8" fill="#e25b4d"/>
          <rect x="285.35" y="628.76" width="404.22" height="81.3" fill="#c8412e"/>
          <rect x="1023.2" y="496.66" width="14.95" height="143.8" fill="#e25b4d"/>
          <rect x="1193.28" y="501.24" width="24.42" height="221.76" fill="#e25b4d"/>
          <rect x="891.33" y="593.85" width="16.6" height="129.15" fill="#e25b4d"/>
          <rect x="958.13" y="586.47" width="16.6" height="136.53" fill="#e25b4d"/>
          <rect x="912.24" y="495.1" width="27.49" height="186.46" fill="#e25b4d"/>
          <rect x="1243.98" y="439.76" width="31.99" height="283.24" fill="#e25b4d"/>
          <rect x="1303.3" y="485.98" width="44.78" height="207.54" fill="#e25b4d"/>
          <polygon points="604.88 720.52 604.88 521.25 580.46 514.18 580.46 720.52 604.88 720.52" fill="#e25b4d"/>
          <rect x="549.82" y="501.24" width="24.42" height="221.76" fill="#e25b4d"/>
          <rect x="608.98" y="447.1" width="31.99" height="275.9" fill="#e25b4d"/>
          <polygon points="521.25 706.44 489.26 706.44 489.26 447.3 521.25 419.06 521.25 706.44" fill="#e25b4d"/>
          <rect x="689.56" y="507.16" width="44.77" height="207.52" fill="#e25b4d"/>
          <rect x="667.65" y="601.71" width="647.66" height="107.09" fill="#c8412e"/>
          <path d="M810,269.49c0-2.31-1.65-4.48-4.53-6.36A16.69,16.69,0,0,0,810,252.07c0-5.61-3.2-10.73-8.47-14.67v-6.27c0-4.4-7.79-8.12-18.54-9.37V140.13c3-1.57,5-4,5-6.75,0-3.38-3-6.28-7.28-7.68V77.92H778.5V60.23h-1.23V0h-4.59V60.23H771.5V77.92h-2.22V125.7c-4.3,1.4-7.27,4.3-7.27,7.68,0,2.75,2,5.18,5,6.75v81.63c-10.75,1.25-18.55,5-18.55,9.37v6.27c-5.27,3.94-8.47,9.06-8.47,14.67a16.74,16.74,0,0,0,4.53,11.06c-2.87,1.88-4.53,4.05-4.53,6.36,0,5.73,10,10.58,23.92,12.29-1.6,200-21.48,441.22-21.48,441.22h65.19S787.74,481.82,786.1,281.78C800,280.07,810,275.22,810,269.49Z" fill="#a83226"/>
          <rect x="395.31" y="636.81" width="339.13" height="86.19" fill="#a83226"/>
          <path d="M732.92,679.28c-2.15-58.24-76.55-105-168-105S399,621,396.84,679.28Z" fill="#a83226"/>
          <path d="M678.25,652.19c-16.06-30.21-60.74-52-113.37-52s-97.31,21.74-113.37,52Z" fill="#771b1b"/>
          <rect x="721.07" y="595.13" width="53.82" height="127.87" fill="#a83226"/>
          <rect x="889.13" y="533.93" width="48.5" height="189.07" fill="#a83226"/>
          <polygon points="864.01 723 815.51 723 815.51 533.93 864.01 505.65 864.01 723" fill="#a83226"/>
          <rect x="942.29" y="524.36" width="73.11" height="198.64" fill="#a83226"/>
          <rect x="1032.2" y="568.57" width="55.75" height="154.43" fill="#a83226"/>
          <rect x="1216.63" y="528.72" width="73.11" height="194.28" fill="#a83226"/>
          <rect x="1306.54" y="511.36" width="71.5" height="211.64" fill="#a83226"/>
          <rect x="1103.09" y="611.02" width="55.75" height="111.98" fill="#a83226"/>
          <rect x="1168.06" y="582.96" width="38.69" height="140.04" fill="#a83226"/>
          <rect x="1383.63" y="559.3" width="23.3" height="163.7" fill="#a83226"/>
          <rect x="768.98" y="618.07" width="60.82" height="104.93" fill="#a83226"/>
          <rect x="903.31" y="632.1" width="60.82" height="90.9" fill="#a83226"/>
          <rect x="984.99" y="615.59" width="60.82" height="107.41" fill="#a83226"/>
          <rect x="1052.91" y="667.95" width="60.82" height="55.05" fill="#a83226"/>
          <rect x="1145.93" y="676.25" width="45.68" height="46.75" fill="#a83226"/>
          <rect x="1193.79" y="638.69" width="45.68" height="84.31" fill="#a83226"/>
          <rect x="1272.54" y="631.97" width="45.69" height="91.03" fill="#a83226"/>
          <rect x="1415.92" y="577.92" width="10.6" height="145.08" fill="#a83226"/>
          <rect x="1429.29" y="614.36" width="10.6" height="108.64" fill="#a83226"/>
          <rect x="1437.78" y="647.59" width="10.59" height="75.41" fill="#a83226"/>
          <rect x="1443.07" y="661.64" width="10.6" height="61.36" fill="#a83226"/>
          <rect x="1450.9" y="631.97" width="13.3" height="91.03" fill="#a83226"/>
          <polygon points="324.48 503.27 324.48 495.03 319.64 495.03 319.64 487.69 316.32 487.69 316.32 482.12 283.06 482.12 283.06 487.69 279.74 487.69 279.74 495.03 274.9 495.03 274.9 503.27 271.07 503.27 271.07 723 328.31 723 328.31 503.27 324.48 503.27" fill="#a83226"/>
          <polygon points="1180.89 414.69 1180.89 403.12 1175.13 403.12 1175.13 392.82 1171.18 392.82 1171.18 385 1131.58 385 1131.58 392.82 1127.63 392.82 1127.63 403.12 1121.87 403.12 1121.87 414.69 1117.3 414.69 1117.3 723 1185.45 723 1185.45 414.69 1180.89 414.69" fill="#a83226"/>
          <rect x="320.96" y="617.71" width="39.68" height="105.29" fill="#a83226"/>
          <rect x="208.06" y="652.46" width="30.94" height="70.54" fill="#a83226"/>
          <rect x="244.12" y="599.24" width="21.47" height="123.76" fill="#a83226"/>
          <rect x="356.09" y="582.14" width="38.69" height="140.86" fill="#a83226"/>
          <rect x="191.18" y="691.04" width="33.75" height="31.96" fill="#a83226"/>
          <rect x="133.78" y="691.06" width="1337.6" height="31.94" fill="#a83226"/>
          <rect x="181.64" y="660.59" width="45.68" height="62.41" fill="#a83226"/>
          <rect x="91.31" y="691.92" width="16.84" height="31.08" fill="#a83226"/>
          <rect x="110.93" y="668.75" width="11.68" height="54.25" fill="#a83226"/>
          <rect x="76.93" y="687.72" width="24.86" height="35.28" fill="#a83226"/>
          <rect x="53" y="710.08" width="96.63" height="12.92" fill="#a83226"/>
          <rect x="36.93" y="696.51" width="24.86" height="26.49" fill="#c8412e"/>
          <polygon points="0 722.96 1599 722.96 1599 722.96 0 722.96" fill="#a83226"/>
          <rect x="1486.67" y="678.08" width="16.84" height="44.92" fill="#a83226"/>
          <rect x="1506.29" y="648.57" width="11.68" height="74.43" fill="#a83226"/>
          <rect x="1472.29" y="672" width="24.86" height="51" fill="#a83226"/>
          <rect x="260.39" y="628.33" width="45.68" height="94.67" fill="#a83226"/>
          <rect x="368.68" y="626.77" width="45.68" height="96.23" fill="#a83226"/>
          <rect x="852.56" y="643.58" width="60.82" height="79.42" fill="#a83226"/>
          <rect x="51.31" y="700.33" width="16.84" height="22.67" fill="#a83226"/>
          <path d="M1045.68,5.7c12.68-3.11,27,3.35,33.21,14.94a15.54,15.54,0,0,1,17.65-4.18,15.77,15.77,0,0,1,9.08,15.8c8-8.55,23.9-7.22,30.4,2.55,2.61,3.91,3.69,9.22,1.46,13.36-3.1,5.76-10.71,6.83-17.2,7.16-31.55,1.6-65.88-2.63-97.5-.35-10.11.73-20-.27-20.71-10.16-.92-12,13.34-16,20.76-10.91C1024.22,20.94,1032.62,8.91,1045.68,5.7Z" fill="#efedec"/>
          <path d="M1343.48,25.34c20.61-3.67,44,3.94,54,17.6,6.8-5.87,19-8,28.71-4.92s16,11,14.78,18.63c13-10.09,38.87-8.52,49.44,3,4.24,4.61,6,10.87,2.37,15.76-5,6.78-17.42,8-28,8.43-51.32,1.89-94.75,3.83-145,2.66-13.57-.31-47.32,0-47.25-15.25.07-14.28,21.69-18.68,33.76-12.66C1308.56,43.3,1322.23,29.12,1343.48,25.34Z" fill="#efedec"/>
          <path d="M842.81,144.26c4.57-2.06,10.34-2.12,14.23,1.05,2.77-12.59,16.27-21.73,28.88-19.55,1.89.32,3.91.85,5.64,0a9.11,9.11,0,0,0,3.12-3.2A35.82,35.82,0,0,1,931.14,109c13.36,3.19,24.28,15,26.53,28.61a25.27,25.27,0,0,1,25.62-1A25.65,25.65,0,0,1,996.14,159a11.28,11.28,0,0,1-.94,4.72c-2.24,4.56-8.29,5.27-13.34,5.38-34.66.73-69.33,1.13-104,1.44-10.79.1-23,2.29-33.52-.42C826.68,165.68,825.76,152,842.81,144.26Z" fill="#efedec"/>
          <path d="M1483.21,143.75c4.7-.84,10,.9,12.31,4a7.37,7.37,0,0,1,6.55-1.12c2.2.7,3.64,2.51,3.36,4.25,3-2.3,8.87-1.94,11.28.68a2.81,2.81,0,0,1,.54,3.59c-1.15,1.55-4,1.84-6.38,1.93-11.7.43-21.6.87-33.06.6-3.09-.07-10.79,0-10.77-3.47,0-3.26,4.94-4.26,7.69-2.89C1475.25,147.85,1478.37,144.61,1483.21,143.75Z" fill="#efedec"/>
          <path d="M340.68,25.33c-3.43-1.9-10.72-1.86-13.58.83a9.1,9.1,0,0,0-4.09-7,18.82,18.82,0,0,0-7.8-2.79c-2-.33-4.41-.42-5.92,1a12.62,12.62,0,0,0-7.24-7.2,14.34,14.34,0,0,0-10.24.1c-2.47,1-4.88,2.89-5.12,5.55a11.61,11.61,0,0,0-10.75.82c-3.09,2.12-4.76,6.37-3.42,9.89A6.09,6.09,0,0,0,270,38.33l2.22.79c6.21.3,60.13,1.41,72.25-.84C347.11,37.77,348.85,29.85,340.68,25.33Z" fill="#efedec"/>
          <path d="M1234.93,273.24a7.06,7.06,0,0,0-7.76,1.32,3.88,3.88,0,0,0-1.94-3.75,9.08,9.08,0,0,0-4.26-1.12c-2.09-.13-4.7.31-5.33,2.32a7.26,7.26,0,0,0-12.82-3.2,9.32,9.32,0,0,0-2.71-6.75,14.67,14.67,0,0,0-6.35-3.72,16.4,16.4,0,0,0-11.12.2,11.58,11.58,0,0,0-7.05,8.35,6.89,6.89,0,0,0,1,5.19,5.27,5.27,0,0,0-5.23-.47,5.37,5.37,0,0,0-3,4.37l.51,2.27c.49,1,1.86,1.23,3,1.28q25.52,1,51.06.05a76.61,76.61,0,0,0,10.15-.83,6.76,6.76,0,0,0,2.55-.82,2.67,2.67,0,0,0,1.33-2.23A3.15,3.15,0,0,0,1234.93,273.24Z" fill="#efedec"/>
          <rect className={styles.box} x="115.81" y="121.41" width="462" height="252" fill="#232323"/>
          <polygon points="62.66 125.66 60.56 137.72 62.66 137.72 64.82 126.18 62.66 125.66" fill="#a83226"/>
          <polygon points="89.72 126.49 91.81 138.55 89.72 138.55 87.55 127.01 89.72 126.49" fill="#a83226"/>
          <path className={styles.box} d="M114.76,149.82a.55.55,0,0,1-.28-.07L98.6,140.42a.54.54,0,0,1-.27-.73.51.51,0,0,1,.27-.27c6.29-3.19,15.86-8.08,16.47-8.52a.54.54,0,0,1,.77.08.55.55,0,0,1-.06.76c-.49.43-11.26,5.91-15.79,8.21l15,8.81a.54.54,0,0,1,.2.74h0A.54.54,0,0,1,114.76,149.82Zm.34-18.88Z" fill="#a83226"/>
          <path d="M69.09,100.17c-9.38.26-16.84,4.9-17.47,13.42s5.12,17.06,24.4,17.27,29.05-16.43,29.05-16.43l34.94-10-2-4h-34.6S84.83,99.73,69.09,100.17Z" fill="#a83226"/>
          <path d="M51.62,113.43a16.41,16.41,0,0,0,.11,3.43c4.65.52,10.3-.78,14.75-1.08a9,9,0,0,0,4.76-1.2,6.51,6.51,0,0,0,2.39-4.47,11.71,11.71,0,0,0-3.62-10H69C59.57,100.41,52.25,104.9,51.62,113.43Z" fill="#771b1b"/>
          <path d="M70,100.13H68.71a11.56,11.56,0,0,1,2.68,9A6.51,6.51,0,0,1,69,113.6a9.81,9.81,0,0,1-4.76,1.83c-3.79.25-8.45.61-12.63.57a6,6,0,0,0,.1.85c4.65.52,9.47-.13,13.92-.42a13,13,0,0,0,5.59-1.86,6.51,6.51,0,0,0,2.39-4.47A11.75,11.75,0,0,0,70,100.13Z" fill="#e25b4d"/>
          <path d="M73.73,101.32c.14-.41,1.64-12,1.64-12h2.14L79.65,102Z" fill="#a83226"/>
          <polygon points="136.16 100.43 140.01 93.42 141.78 93.42 140.01 104.44 136.16 100.43" fill="#a83226"/>
          <polygon points="76.02 87.59 34.52 85.31 31.47 88.3 76.02 88.74 76.02 87.59" fill="#771b1b"/>
          <polygon points="77.06 88.57 118.08 88.71 120.98 86.03 77.02 87.6 77.06 88.57" fill="#771b1b"/>
          <path d="M78.6,88.34a2,2,0,1,1-2-2A2,2,0,0,1,78.6,88.34Z" fill="#e25b4d"/>
          <polygon points="139.91 93.42 133.22 89.11 133.79 86.62 140.69 92.85 139.91 93.42" fill="#771b1b"/>
          <polygon points="141.75 93.87 148 98.79 147.2 101.22 140.92 94.36 141.75 93.87" fill="#771b1b"/>
          <path d="M51.6,137l47.4.73v2.6l-48.11-1.66s-3-.52-4.06-2.92a7.65,7.65,0,0,1-.73-4.05,11.07,11.07,0,0,0,2.08,3.43C50,137.2,51.6,137,51.6,137Z" fill="#a83226"/>
          <path d="M57.44,105.15a7,7,0,0,0-2.77,4.52,2.45,2.45,0,0,0,.54,2.11,2.08,2.08,0,0,0,2.6-.08,5.49,5.49,0,0,0,1.53-2.34,7.54,7.54,0,0,1,1.35-2.47c.91-1,2.29-1.32,3.32-2.15a1.6,1.6,0,0,0,.71-1.5C64.11,100.89,58.39,104.41,57.44,105.15Z" fill="#a83226"/>
          <path d="M141.47,95.39l-.78-.31-3,6.88-34.94,10S93,128.6,73.69,128.39C64,128.28,57.78,126.1,54,122.87c3.25,4.54,10,7.87,22,8,19.27.2,29.05-16.43,29.05-16.43l34.94-10Z" fill="#771b1b"/>
          <path d="M143.06,93.61a2,2,0,1,1-2-2A2,2,0,0,1,143.06,93.61Z" fill="#e25b4d"/>
          <path d="M87.79,110.12a14,14,0,0,0-6.86,0,2.76,2.76,0,0,0-1.59,1.06c-1,1.54,1,3.56.37,5.27-.41,1.21-1.85,1.67-3.1,1.93A67.33,67.33,0,0,1,52.1,119a12.74,12.74,0,0,0,1.37,3.06q14.61-.33,29.2-1.51c1-.08,2.25-.32,2.59-1.29.43-1.2-.88-2.78,0-3.69a2,2,0,0,1,1.74-.32,25.51,25.51,0,0,1,9.24,3.17A15.5,15.5,0,0,1,99.61,121a39.79,39.79,0,0,0,4.15-4.74A56.21,56.21,0,0,0,87.79,110.12Z" fill="#e25b4d"/>
          <path d="M75,103a9.63,9.63,0,0,0,3.63,0,77.94,77.94,0,0,1,14,.18,14.72,14.72,0,0,0,4.14-.09c.45-.09,1-.34,1-.8s-.72-.8-1.29-.82c-1.18,0-2.36.12-3.54.2a51.51,51.51,0,0,1-10.16-.33A34.92,34.92,0,0,0,76,101c-.53,0-1.42-.1-1.83.32C73.47,102,74.26,102.78,75,103Z" fill="#a83226"/>
          <rect className={styles.box} x="115.81" y="121.41" width="462" height="79" fill="#1a1a1a"/>
        
          {/* text */}
          <text className={styles.text} transform="translate(125.81 180.35)" fontSize="64" fill="#eb9459" fontFamily="Calibre-Medium, Calibre" fontWeight="100"> contact </text>
          <text className={styles.text} transform="translate(125.81 234.85)" fontSize="32" fill="#efedec" fontFamily="Avenir-Medium, Avenir" fontWeight="100"> location: toronto, canada </text>
          <text className={styles.text} transform="translate(125.81 273.29)" fontSize="32" fill="#efedec" fontFamily="Avenir-Medium, Avenir" fontWeight="100"> email: smitp505@gmail.com </text> 
          
          {/* links */}
          <a href="https://www.linkedin.com/in/smit-patel-uoft/">
            <path className={styles.link} d="M141.73,328.64a18,18,0,1,0,18,18A18,18,0,0,0,141.73,328.64Zm-3,24h-3v-9h3Zm-1.5-10.34a1.67,1.67,0,1,1,1.65-1.66A1.65,1.65,0,0,1,137.23,342.3Zm12,10.34h-3v-4.29c0-2.83-3-2.59-3,0v4.29h-3v-9h3v1.64c1.31-2.43,6-2.61,6,2.32Z" fill="#efedec"/>
          </a>
          <a href="https://github.com/smite1921">
            <path className={styles.link} d="M193.88,328.64a18.45,18.45,0,0,0-5.83,36c.92.18,1.22-.4,1.22-.88v-3.44c-5.14,1.12-6.21-2.18-6.21-2.18a4.9,4.9,0,0,0-2.05-2.7c-1.67-1.14.13-1.12.13-1.12a3.89,3.89,0,0,1,2.83,1.9,3.93,3.93,0,0,0,5.37,1.54,3.9,3.9,0,0,1,1.17-2.47c-4.1-.47-8.41-2.05-8.41-9.12a7.13,7.13,0,0,1,1.91-4.95,6.6,6.6,0,0,1,.17-4.89s1.56-.49,5.08,1.89a17.52,17.52,0,0,1,9.24,0c3.53-2.38,5.07-1.89,5.07-1.89a6.64,6.64,0,0,1,.19,4.89,7.13,7.13,0,0,1,1.89,4.95c0,7.09-4.31,8.65-8.42,9.11a4.4,4.4,0,0,1,1.26,3.41v5.07c0,.49.3,1.06,1.24.88a18.46,18.46,0,0,0-5.85-36Z" fill="#efedec"/>
          </a>
          <a href="mailto:smitp505@gmail.com">
            <path className={styles.link} d="M246,328.64a18,18,0,1,0,18,18A18,18,0,0,0,246,328.64Zm10.49,10.47L246,347.61l-10.48-8.5Zm0,15h-21V341.35l10.5,8.51,10.5-8.51Z" fill="#efedec"/>
          </a> 
          <a href="https://www.instagram.com/smit._.p/">
            <path className={styles.link} d="M302,338.09c-1.11,0-1.44-.06-4.24-.06s-3.14,0-4.24.06c-2.85.13-4.18,1.48-4.31,4.31-.05,1.1-.06,1.43-.06,4.24s0,3.13.06,4.24c.13,2.82,1.45,4.18,4.31,4.31,1.1.05,1.43.06,4.24.06s3.13,0,4.24-.06c2.85-.13,4.17-1.49,4.31-4.31.05-1.11.06-1.44.06-4.24s0-3.14-.06-4.24C306.14,339.57,304.82,338.22,302,338.09ZM297.73,352a5.39,5.39,0,1,1,5.39-5.39A5.39,5.39,0,0,1,297.73,352Zm5.6-9.74a1.26,1.26,0,1,1,1.26-1.26A1.26,1.26,0,0,1,303.33,342.29Zm-2.1,4.35a3.5,3.5,0,1,1-3.5-3.5A3.5,3.5,0,0,1,301.23,346.64Zm-3.5-18a18,18,0,1,0,18,18A18,18,0,0,0,297.73,328.64ZM308.17,351c-.18,3.81-2.3,5.93-6.11,6.1-1.12.06-1.48.07-4.33.07s-3.21,0-4.33-.07c-3.82-.17-5.93-2.29-6.11-6.1-.05-1.12-.06-1.48-.06-4.33s0-3.21.06-4.33c.18-3.82,2.29-5.93,6.11-6.11,1.12-.05,1.48-.06,4.33-.06s3.21,0,4.33.06c3.82.18,5.93,2.3,6.11,6.11.05,1.12.06,1.48.06,4.33S308.22,349.85,308.17,351Z" fill="#efedec"/>
          </a>
        </svg>
      
        
        <div className={styles.bottom}>

          <div className={styles.mobile}>

            <div className={`${styles.title} cal-l`}>
              <Header title='contact' color={COLOR.WHITE} font={FONT.CAL_MED}/>
            </div>

            <div className={`${styles.body} m`}>
              <Header title='location: toronto, canada' color={COLOR.WHITE} font={FONT.AVENIR_MED}/>
              <Header title='email: smitp505@gmail.com' color={COLOR.WHITE} font={FONT.AVENIR_MED}/>
            </div>
            <div className={styles.links}>
              <Social/>
            </div>
          </div>

        </div>

        <div className={styles.footer}>
          <div className='xs'>
            <Header title={`this website was designed and made by smit patel.`} font={FONT.MONO} color={COLOR.WHITE}/> 
            <Header title='credits' link='/credits' font={FONT.MONO} color={COLOR.WHITE}/> 
          </div>
        </div>          

      </div>
    );
  }